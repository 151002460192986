import React, { useState, useEffect } from "react";

const LoadableImage = ({ src, alt, style, ...props }) => {
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    const loadImage = async () => {
      if (!src || src.indexOf("assets/files") === -1) return setImageUrl(src);
      // https://career.pamapersada.com/
      const domainAssets =
        window.location.hostname === "localhost"
          ? `https://dev-veera.shratech.com/${src}`
          : `${window.location.origin}/${src}`;

      // const newSrc =
      //   src && src.indexOf("assets/files") !== -1 ? domainAssets : src;
      const newSrc = domainAssets;

      try {
        const response = await fetch(newSrc, {
          headers: { "file-headers": "F1L3-H34D3RS" },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const objectURL = URL.createObjectURL(blob);
        setImageUrl(objectURL);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [src]);

  return <img src={imageUrl} alt={alt} style={style} {...props} />;
};

export default LoadableImage;
