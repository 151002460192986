// PAMA
// export const config = {
//   apiKey: "AIzaSyBCClqSovpp-ojwV_5DiTOGPAOwSH4Kae0",
//   authDomain: "frbrecruitment-5e80f.firebaseapp.com",
//   projectId: "frbrecruitment-5e80f",
//   storageBucket: "frbrecruitment-5e80f.appspot.com",
//   messagingSenderId: "245916523884",
//   appId: "1:245916523884:web:942bb9840e7c54a19aa749",
//   measurementId: "G-1G20JL74KK",
// };

// // local
export const config = {
  apiKey: "AIzaSyA1jz9GPMjEQyIdP3VEcm9JnZNQfrzPFVk",
  authDomain: "colagio-25706.firebaseapp.com",
  databaseURL: "https://colagio-25706.firebaseio.com",
  projectId: "colagio-25706",
  storageBucket: "colagio-25706.appspot.com",
  messagingSenderId: "623441144044",
  appId: "1:623441144044:web:0f87d1d972ff43a091ddc9",
  measurementId: "G-F32PV1N4ZF",
};
