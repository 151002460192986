import React from "react";
import { Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomInput from "./CustomInput";

const CustomDatePicker = ({
  label,
  name,
  onChange,
  value,
  error,
  helpertext,
  ...rest
}) => {
  return (
    <div>
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        {label}
      </Typography>
      <DatePicker
        label=""
        value={value}
        inputFormat="dd/MM/yyyy"
        onChange={(d) => onChange(d, name)}
        renderInput={(params) => (
          <CustomInput
            {...params}
            error={error ? error : false}
            helperText={helpertext ? helpertext : false}
          />
        )}
        {...rest}
      />
    </div>
  );
};

export default CustomDatePicker;
