import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  styled,
  CircularProgress,
  DialogActions,
  Stack,
} from "@mui/material";
import {
  getRefRegion,
  updateJpExtensionCn,
  setSnackbarOpen,
} from "src/redux/actions";
import { useNavigate } from "react-router";
import { addDays } from "date-fns";
import { Calendar } from "react-date-range";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    // maxWidth: "350px",
  },
}));

const CustomCircularProgress = styled(CircularProgress)(
  ({ theme, loading }) => ({
    "&.MuiCircularProgress-root": {
      height: "20px !important",
      width: "20px !important",
      display: "flex",
      color: loading === "google" ? `${theme.palette.primary.main}` : "#ffff",
      marginRight: loading === "google" ? "0.5rem !important" : null,
    },
  })
);

const ModalSelectDateMCU = ({ open, onClose, dataDetail }) => {
  const navigate = useNavigate();
  useTheme();
  const [loading, setLoading] = useState("content");
  const [cities, setCities] = useState([]);
  const [form, setForm] = useState({});
  const [filingDate, setFilingDate] = useState(new Date());

  useEffect(() => {
    fetchRef();
  }, []);

  const fetchRef = async () => {
    const resRef = await Promise.all([getRefRegion({ province: "all" })]);
    setCities(resRef[0].data);
    setLoading("");
  };

  const handleSelectAuto = async (val, key) => {
    setForm({ ...form, [key]: val[key] });
  };

  const handleApply = async () => {
    setLoading("buttonApply");
    const resApply = await updateJpExtensionCn({
      candidate_id: dataDetail.candidate_id,
      job_posting_extension_id:
        dataDetail.extension[0]?.job_posting_extension_id,
      city: form.city,
      filing_date: filingDate,
      update_type: "filed-mcu-date",
    });
    if (resApply.code !== 200) {
      return;
    }
    setSnackbarOpen("success", "Anda berhasil mengajukan waktu MCU");
    setLoading("");
    onClose();
  };

  if (loading === "content") return <CustomCircularProgress />;

  return (
    <CustomDialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <b>Pilih Tanggal Kesediaan MCU</b>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Lokasi MCU
        </Typography>
        <CustomAutoComplete
          name="city"
          optionsArr={cities}
          keyLabel={"city"}
          handleSelect={handleSelectAuto}
          register="true"
          style={{ marginBottom: "1rem" }}
          value={form.city}
        />
      </DialogContent>
      <Calendar
        // onChange={(item) => setSelectedDate([item.selection])}
        onChange={(item) => setFilingDate(item)}
        months={2}
        moveRangeOnFirstSelection={false}
        direction="horizontal"
        minDate={new Date()}
        maxDate={addDays(new Date(), 7)}
        date={filingDate}
        // scroll={{ enabled: true }}
        // ranges={selectedDate}
      />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {loading === "buttonApply" ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={handleApply}
            disabled={!form.city}
          >
            Send Confirmation
          </Button>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

export default ModalSelectDateMCU;
