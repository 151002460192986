import UserLayout from "src/components/Layout/UserLayout";
import AdminLayout from "./components/Layout/AdminLayout";
import Article from "./pages/user/Article";
import DetailArticle from "./pages/user/Article/DetailArticle";
import Galery from "./pages/user/Galery";
// import Products from "src/pages/user/Products";
import Products from "src/pages/user/Products/ProductV3";
// import ProductDetail from "src/pages/user/ProductDetail";
import ProductDetail from "src/pages/user/ProductDetail/DetailV3";
// import MyDashboard from "src/pages/user/MyDashboard";
import MyDashboard from "src/pages/user/MyDashboard/DashboardV3";
// import Register from "src/components/Register";
import Register from "src/components/ContainerRegister";
// import LandingUser from "src/pages/user/Landing";
import LandingUser from "src/pages/user/Landing/indexNew";
// import LandingAdmin from "src/pages/admin/Landing";
// import Setting from "src/pages/admin/Setting";
import Setting from "./pages/admin/settingV3/indexV3";
import { Navigate } from "react-router-dom";
// import Profile from "src/pages/user/Profile";
import Profile from "src/pages/user/Profile/ContainerProfile";
import FAQ from "src/pages/user/FAQ";
import EmbedForm from "src/pages/user/EmbedForm";
import CustomPage from "src/pages/user/CustomPage";
// import NotFound from "src/components/NotFound";
import SignA from "src/pages/user/Sign/SignA";
import SignB from "src/pages/user/Sign/SignB";
import ResetPasswordForm from "./pages/user/Auth/ResetPasswordForm";
import { version } from "src/configs/globalVariable";
import Invited from "./pages/user/Invited/Invited";

const routes = [
  {
    path: "",
    children: [{ path: "", element: <Navigate to={`/${version}/user/`} /> }],
  },
  {
    path: `${version}`,
    children: [{ path: "", element: <Navigate to={`/${version}/user/`} /> }],
  },
  {
    path: `/${version}/user`,
    element: <UserLayout />,
    children: [
      { path: "", element: <LandingUser /> },
      { path: "article", element: <Article /> },
      { path: "profile", element: <Profile /> },
      { path: "FAQ", element: <FAQ /> },
      { path: "embed-form", element: <EmbedForm /> },
      { path: "signA", element: <SignA /> },
      { path: "signB", element: <SignB /> },
      { path: "article/:idArticle", element: <DetailArticle /> },
      { path: "galery", element: <Galery /> },
      { path: "invited/:token", element: <Invited /> },
      { path: "dashboard/:dashboard", element: <MyDashboard /> },
      { path: ":product", element: <Products /> },
      { path: ":product/detail", element: <ProductDetail /> },
      { path: ":page/:idGroup", element: <CustomPage /> },
      { path: ":page/:idGroup/:id", element: <CustomPage /> },
      // { path: "*", element: <Navigate to=`/${version}/user/` /> },
    ],
  },
  {
    path: `${version}/admin`,
    element: <AdminLayout />,
    children: [
      // { path: "", element: <Navigate to={`/${version}/admin/setting`} /> }, // element: <LandingAdmin />
      // { path: "setting", element: <Setting /> },
      { path: "", element: <Setting /> },
    ],
  },
  { path: `/${version}/register`, element: <Register /> },
  { path: `/${version}/reset/password/:token`, element: <ResetPasswordForm /> },
  // { path: `/${version}/404`, element: <NotFound /> },
];

export default routes;
