import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  getRefEdu,
  getRefRegion,
  getEducation,
  createEducation,
  updateEducation,
  deleteEducation,
  setSnackbarOpen,
  getRef,
  getRefUniSP,
  getRefSchool,
  getBiodata,
  updateBiodata,
} from "src/redux/actions";
import {
  CustomAutoComplete,
  CustomAutoCompleteAsync,
} from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import CustomDatePicker from "src/components/CustomDatePicker";
import EducationInformal from "./EducationInformal";
import EducationAchievement from "./EducationAchievement";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const refDomesticInter = [
  { domestic_international: "domestic", name: "Dalam Negeri" },
  { domestic_international: "international", name: "Luar Negeri" },
];

const Education = ({ handleChange }) => {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState("");
  const [eduStage, setEduStage] = useState([]);
  const [cities, setCities] = useState([]);
  const [form, setForm] = useState({});
  const [educations, setEducations] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [university, setUniversity] = useState([]);
  const [schools, setSchools] = useState([]);
  const [uniSp, setUniSp] = useState([]);
  const [openAsync, setOpenAsync] = useState(false);
  const [loadingReFetch, setLoadingReFetch] = useState(false);
  const [loadingEdu, setLoadingEdu] = useState(false);
  const [loadingSch, setLoadingSch] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [validate, setValidate] = useState(false);
  const [validateTxt] = useState("* required");
  const [validateTxtMin, setValidateTxtMin] = useState("");
  const [validateTxtMin1, setValidateTxtMin1] = useState("");
  const [validateTxtMin2, setValidateTxtMin2] = useState("");
  const [bio, setBio] = useState({});
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [isEmptyAch, setIsEmptyAch] = useState(false);
  const [isEmptyNon, setIsEmptyNon] = useState(false);
  const validates = JSON.parse(localStorage.getItem("validates"));
  const val = parseInt(localStorage.getItem("value"));

  useEffect(() => {
    fetchRef();
    fetchEdu();
  }, []);

  useEffect(() => {
    if (educations.length < 3) {
      setValidateTxtMin(
        "* required write down your last 3 levels of education"
      );
    } else {
      setValidateTxtMin("");
      validates[val + 1] = true;
      localStorage.setItem("validates", JSON.stringify(validates));
    }
    if (bio?.is_edu_achievement === null) {
      setValidateTxtMin1("* required");
    } else {
      setValidateTxtMin1("");
    }
    if (bio?.is_edu_non === null) {
      setValidateTxtMin2("* required");
    } else {
      setValidateTxtMin2("");
    }
  }, [educations, bio]);

  const fetchRef = async () => {
    const resRef = await Promise.all([
      getRefEdu(),
      getRefRegion({ province: "all" }),
      getRef({ type: "refUniversity" }),
      getRefSchool(),
    ]);
    setEduStage(resRef[0].data);
    setCities(resRef[1].data);
    setUniversity(resRef[2].data);
    setSchools(resRef[3].data);
  };

  const fetchEdu = async () => {
    setLoading(true);
    const resEdu = await Promise.all([getEducation(), getBiodata()]);
    setEducations(resEdu[0].data || []);
    // resEdu[1].data[0].city_ktp = resEdu[1].data[0].city_ktp?.city_ktp;
    // resEdu[1].data[0].kecamatan_ktp = resEdu[1].data[0].kecamatan_ktp?.kecamatan_ktp;
    // resEdu[1].data[0].kelurahan_ktp = resEdu[1].data[0].kelurahan_ktp?.kelurahan_ktp;
    // resEdu[1].data[0].province_ktp = resEdu[1].data[0].province_ktp?.province_ktp;
    resEdu[1].data[0].city = resEdu[1].data[0].city?.city;
    resEdu[1].data[0].kecamatan = resEdu[1].data[0].kecamatan?.kecamatan;
    resEdu[1].data[0].kelurahan = resEdu[1].data[0].kelurahan?.kelurahan;
    resEdu[1].data[0].province = resEdu[1].data[0].province?.province;
    setBio(resEdu[1].data[0] || {});
    setLoading(false);
  };

  const handleUpdateBiodata = async (key, val) => {
    setLoadingCheck(true);
    const newBio = { ...bio, [key]: val };
    await updateBiodata(newBio);
    // fetchEdu();
    setBio(newBio);
    setLoadingCheck(false);
  };

  const handleSelectAuto = async (val, key) => {
    setForm({ ...form, [key]: val[key] });
    if (key === "city") {
      setForm({ ...form, [key]: val });
    } else if (key === "university_id") {
      if (form.educational_code !== "05") {
        // ini kalo bukan SMA
        // const res = await getRefUniSP({
        //   university_id: val[key],
        //   educational_code: form.educational_code,
        // });
        // setUniSp(res.data);
        handleReFetchUniSp(null, val[key], form.educational_code);
      } else {
        setForm({ ...form, [key]: val["school_id"] });
      }
    } else if (key === "university_study_program_id") {
      setForm({
        ...form,
        [key]: val[key],
        educational_code: val.educational_code,
        educational_stage: eduStage.filter(
          (edu) => edu.educational_code === val.educational_code
        )[0],
      });
    }

    if (key === "educational_code") {
      handleReFetchUniSp(null, form.university_id, val[key]);
      // setLoadingEdu(true);
      // setTimeout(() => {
      //   setLoadingEdu(false);
      // }, 300);
    }
  };

  const handleReFetchUniSp = async (val, defUniv, defEduCode) => {
    setLoadingReFetch(true);
    const res = await getRefUniSP({
      university_id: defUniv || form.university_id,
      study_program_name: val,
      educational_code: defEduCode || form.educational_code,
    });
    setUniSp(res.data);
    setLoadingReFetch(false);
  };

  const handleDateChange = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleCheck = (e) => {
    setForm({ ...form, is_still_studying: e.target.checked });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    console.log(d);
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteEducation(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    fetchEdu();
  };

  function tambahkanSatuHari(tanggal) {
    let tanggalBaru = new Date(tanggal);
    tanggalBaru.setHours(tanggalBaru.getHours() + 12);
    return tanggalBaru;
  }

  const handleSave = async () => {
    setValidate(true);
    const conf =
      !form.domestic_international ||
      !form.educational_code ||
      (form.domestic_international === "international" &&
        (!form.school || !form.city || !form.study_program_text)) ||
      (form.domestic_international !== "international" &&
        (!form.university_id || !form.city || !form.thesis_title) &&
        form.educational_code !== "06" &&
        form.educational_code !== "07" &&
        form.educational_code !== "05") ||
      (form.domestic_international !== "international" &&
        form.educational_code === "05" &&
        (!form.school || !form.study_program_text)) ||
      (form.domestic_international !== "international" &&
        (form.educational_code === "06" || form.educational_code === "07") &&
        !form.school) ||
      (form.domestic_international !== "international" &&
        (form.educational_code === "001" ||
          form.educational_code === "002" ||
          form.educational_code === "01" ||
          form.educational_code === "02" ||
          form.educational_code === "03" ||
          form.educational_code === "031" ||
          form.educational_code === "04" ||
          form.educational_code === "041" ||
          form.educational_code === "042") &&
        !form.university_study_program_id) ||
      // (form.domestic_international !== "international" && form.educational_code !== "05" && !form.university_study_program_id) ||
      !form.ipk ||
      !form.started_at ||
      (!form.is_still_studying && !form.finished_at);
    if (conf) return;
    const copyForm = { ...form };
    copyForm.city = form.city?.city;
    copyForm.started_at = tambahkanSatuHari(copyForm.started_at);
    copyForm.finished_at = tambahkanSatuHari(copyForm.finished_at);
    setLoadingButton(true);
    if (openForm === "new") {
      await createEducation(copyForm);
    } else {
      await updateEducation(copyForm);
    }
    setLoadingButton(false);
    setValidate(false);
    setSnackbarOpen("success", "Data disimpan");
    setOpenForm("");
    setForm({});
    fetchEdu();
  };

  const onInputChange = async (val) => {
    setLoadingSch(true);
    const res = await getRefSchool({
      school_name: val,
      educational_code: form.educational_code,
    });
    setSchools(res.data);
    setLoadingSch(false);
  };

  if (loading) return <CircularProgress />;
  if (loadingEdu) return null;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Data Pendidikan</b>
      </Typography>

      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{ m: "1rem 0" }}
      >
        <Typography sx={{ fontSize: "20px", m: "auto 0" }}>
          Pendidikan Formal{" "}
          <span
            style={{ fontSize: "16px" }}
          >{`(Tulis 3 jenjang pendidikan terakhir anda)`}</span>
        </Typography>
        {!openForm && (
          <Button variant="contained" onClick={() => setOpenForm("new")}>
            Tambah
          </Button>
        )}
      </Stack>
      {openForm && (
        <Stack
          sx={{
            border: "1px solid",
            p: "1rem",
            borderRadius: "6px",
            borderColor: "divider",
            mb: "1rem",
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Pendidikan Dalam/Luar Negeri
          </Typography>
          <CustomAutoComplete
            name="domestic_international"
            optionsArr={refDomesticInter}
            keyLabel={"name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            // value={form.domestic_international}
            value={
              refDomesticInter.filter(
                (u) => u.domestic_international === form.domestic_international
              )[0]
            }
            error={validate && !form.domestic_international}
            helpertext={validate && !form.domestic_international && validateTxt}
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Jenjang Pendidikan
          </Typography>
          <CustomAutoComplete
            name="educational_code"
            optionsArr={eduStage}
            keyLabel={"educational_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={
              eduStage.filter(
                (ed) => ed.educational_code === form.educational_code
              )[0]
            }
            error={validate && !form.educational_code}
            helpertext={validate && !form.educational_code && validateTxt}
          />
          {form.educational_code === "001" ||
          form.educational_code === "002" ||
          form.educational_code === "01" ||
          form.educational_code === "02" ||
          form.educational_code === "03" ||
          form.educational_code === "031" ||
          form.educational_code === "04" ||
          form.educational_code === "041" ||
          form.educational_code === "042" ? (
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {`Nama Sekolah / Universitas ${
                form.domestic_international !== "international"
                  ? " (Sesuai data BAN-PT)"
                  : ""
              }`}
            </Typography>
          ) : (
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {`Nama Sekolah / Universitas`}
            </Typography>
          )}
          {form.domestic_international === "international" && (
            <CustomInput
              label=""
              name="school"
              onChange={handleChangeInput}
              value={form.school}
              error={validate && !form.school}
              helpertext={validate && !form.school && validateTxt}
            />
          )}

          {form.domestic_international !== "international" && (
            <>
              {/* TODO: ini nama sekolah yg dropdown, minta ditakeout dulu */}
              {/* {form.educational_code === "05" && (
                <>
                  <CustomAutoCompleteAsync
                    name="university_id"
                    optionsArr={schools}
                    keyLabel={"school_name"}
                    handleSelect={handleSelectAuto}
                    register="true"
                    style={{ marginBottom: "1rem" }}
                    open={openAsync}
                    setOpen={setOpenAsync}
                    loading={loadingSch}
                    onInputChange={(event, newInputValue) => {
                      onInputChange(newInputValue);
                    }}
                    value={
                      schools.filter(
                        (u) => u.school_id === form.university_id
                      )[0]
                    }
                    error={validate && !form.university_id}
                    helpertext={validate && !form.university_id && validateTxt}
                  />
                </>
              )} */}
              {(form.educational_code === "001" ||
                form.educational_code === "002" ||
                form.educational_code === "01" ||
                form.educational_code === "02" ||
                form.educational_code === "03" ||
                form.educational_code === "031" ||
                form.educational_code === "04" ||
                form.educational_code === "041" ||
                form.educational_code === "042") && (
                <>
                  <CustomAutoComplete
                    name="university_id"
                    optionsArr={university}
                    keyLabel={"university_name"}
                    handleSelect={handleSelectAuto}
                    register="true"
                    style={{ marginBottom: "1rem" }}
                    value={
                      university.filter(
                        (u) => u.university_id === form.university_id
                      )[0]
                    }
                    error={validate && !form.university_id}
                    helpertext={validate && !form.university_id && validateTxt}
                  />
                </>
              )}
              {(form.educational_code === "06" ||
                form.educational_code === "07" ||
                form.educational_code === "05") && (
                <CustomInput
                  label=""
                  name="school"
                  onChange={handleChangeInput}
                  value={form.school}
                  error={validate && !form.school}
                  helpertext={validate && !form.school && validateTxt}
                />
              )}
            </>
          )}
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Kota
          </Typography>
          {form.domestic_international === "international" ? (
            <CustomInput
              label=""
              name="city"
              onChange={handleChangeInput}
              value={form.city}
              error={validate && !form.city}
              helpertext={validate && !form.city && validateTxt}
            />
          ) : (
            <CustomAutoComplete
              name="city"
              optionsArr={cities}
              keyLabel={"city"}
              handleSelect={handleSelectAuto}
              register="true"
              style={{ marginBottom: "1rem" }}
              value={form.city}
              error={validate && !form.city}
              helpertext={validate && !form.city && validateTxt}
            />
          )}
          {(form.educational_code === "001" ||
            form.educational_code === "002" ||
            form.educational_code === "01" ||
            form.educational_code === "02" ||
            form.educational_code === "03" ||
            form.educational_code === "031" ||
            form.educational_code === "04" ||
            form.educational_code === "041" ||
            form.educational_code === "042") && (
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              {`Jurusan/Fakultas ${
                form.domestic_international !== "international"
                  ? " (Sesuai data BAN-PT)"
                  : ""
              }`}
            </Typography>
          )}
          {form.educational_code === "05" && (
            <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
              Jurusan
            </Typography>
          )}
          {form.domestic_international === "international" ? (
            <CustomInput
              label=""
              name="study_program_text"
              onChange={handleChangeInput}
              value={form.study_program_text}
              error={validate && !form.study_program_text}
              helpertext={validate && !form.study_program_text && validateTxt}
            />
          ) : (
            <>
              {form.educational_code === "05" && (
                <CustomInput
                  label=""
                  name="study_program_text"
                  onChange={handleChangeInput}
                  value={form.study_program_text}
                  error={validate && !form.study_program_text}
                  helpertext={
                    validate && !form.study_program_text && validateTxt
                  }
                />
              )}
              {(form.educational_code === "001" ||
                form.educational_code === "002" ||
                form.educational_code === "01" ||
                form.educational_code === "02" ||
                form.educational_code === "03" ||
                form.educational_code === "031" ||
                form.educational_code === "04" ||
                form.educational_code === "041" ||
                form.educational_code === "042") && (
                <CustomAutoComplete
                  name="university_study_program_id"
                  optionsArr={loadingReFetch ? [] : uniSp}
                  loading={loadingReFetch}
                  keyLabel={"sp_stage_name"}
                  handleSelect={handleSelectAuto}
                  fetchData={handleReFetchUniSp}
                  register="true"
                  style={{ marginBottom: "1rem" }}
                  value={
                    uniSp.filter(
                      (u) =>
                        u.university_study_program_id ===
                        form.university_study_program_id
                    )[0]
                  }
                  error={validate && !form.university_study_program_id}
                  helpertext={
                    validate && !form.university_study_program_id && validateTxt
                  }
                />
              )}
            </>
          )}
          <CustomInput
            label="IPK/Nilai Ijazah (Gunakan tanda desimal (.) untuk memisahkan nilai dan pecahan)"
            name="ipk"
            onChange={handleChangeInput}
            value={form.ipk}
            placeholder="Contoh: 3.75"
            error={validate && !form.ipk}
            helpertext={validate && !form.ipk && validateTxt}
          />
          <CustomDatePicker
            label="Tanggal Mulai"
            onChange={handleDateChange}
            name="started_at"
            value={form.started_at ? form.started_at : null}
            error={validate && !form.started_at}
            helpertext={validate && !form.started_at && validateTxt}
          />
          <Stack direction="row" spacing={1} sx={{ mb: "1rem" }}>
            <FormGroup sx={{ m: "auto 0" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.is_still_studying}
                    onChange={handleCheck}
                  />
                }
                label="Masih Studi"
              />
            </FormGroup>
            {!form.is_still_studying && (
              <CustomDatePicker
                label="Tanggal Lulus"
                onChange={handleDateChange}
                name="finished_at"
                value={form.finished_at ? form.finished_at : null}
                error={validate && !form.finished_at}
                helpertext={validate && !form.finished_at && validateTxt}
              />
            )}
          </Stack>
          {(form.educational_code === "042" ||
            form.educational_code === "041" ||
            form.educational_code === "04" ||
            form.educational_code === "031" ||
            form.educational_code === "03" ||
            form.educational_code === "02" ||
            form.educational_code === "01" ||
            form.educational_code === "001" ||
            form.educational_code === "002") && (
            <CustomInput
              label="Judul TA/Skripsi"
              name="thesis_title"
              onChange={handleChangeInput}
              value={form.thesis_title}
              multiline
              rows={2}
              error={validate && !form.thesis_title}
              helpertext={validate && !form.thesis_title && validateTxt}
            />
          )}
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"end"}
            sx={{ my: "1rem" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={loadingButton}
              startIcon={loadingButton ? <CircularProgress /> : null}
            >
              Simpan
            </Button>
          </Stack>
          {/* <Divider sx={{ mt: 2, mb: 2 }} /> */}
        </Stack>
      )}

      <Grid container>
        <Grid item sm={2} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Jenjang Pendidikan</Typography>
        </Grid>
        <Grid item sm={3} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>
            Nama Sekolah/Universitas
          </Typography>
        </Grid>
        <Grid item sm={2} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Kota</Typography>
        </Grid>
        <Grid item sm={4} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Jurusan</Typography>
        </Grid>
        <Grid item sm={1} sx={{ m: "auto" }}></Grid>
      </Grid>

      {validateTxtMin && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
        >
          {validateTxtMin}
        </Typography>
      )}

      {educations.map((edu) => (
        <Card
          id={edu.bio_edu_id}
          sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "16px" }}
        >
          <Grid container>
            <Grid item sm={2} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {edu.educational_stage?.educational_name}
              </Typography>
            </Grid>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu?.university?.university_name || edu?.school?.school_name}
              </Typography>
            </Grid>
            <Grid item sm={2} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu.city?.city}
              </Typography>
            </Grid>
            <Grid item sm={4} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu?.study_program?.study_program_name ||
                  edu?.study_program_text}
              </Typography>
            </Grid>
            <Grid item sm={1} sx={{ m: "auto" }}>
              <Stack direction="row" justifyContent={"end"} spacing={1}>
                <IconButton onClick={() => handleEdit(edu)}>
                  <EditRoundedIcon />{" "}
                </IconButton>
                <IconButton onClick={() => handleDelete(edu)}>
                  <DeleteRoundedIcon />{" "}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      ))}

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          Data {form?.university?.university_name || form?.school?.school_name}{" "}
          - {form.educational_stage?.educational_name} akan dihapus
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>

      {/* -------------------- Prestasi semasa studi --------------- */}
      <Grid container sx={{ mt: "4rem" }}>
        <Grid item md={8} sm={12} sx={{ m: "auto 0" }}>
          <Typography>
            Pernahkah Anda meraih prestasi selama masa studi Anda?
          </Typography>
        </Grid>
        <Grid item md={2} sm={12}>
          {loadingCheck && <CircularProgress size={24} />}
          {!loadingCheck && (
            <Stack direction={"row"} spacing={2}>
              <FormControlLabel
                label="Tidak"
                error={validate && bio.is_edu_achievement === null}
                control={
                  <Checkbox
                    checked={bio?.is_edu_achievement === false}
                    onClick={(e) =>
                      handleUpdateBiodata("is_edu_achievement", false)
                    }
                  />
                }
              />
              <FormControlLabel
                label="Pernah"
                error={validate && form.is_edu_achievement === null}
                control={
                  <Checkbox
                    checked={bio?.is_edu_achievement}
                    onClick={(e) =>
                      handleUpdateBiodata("is_edu_achievement", true)
                    }
                  />
                }
              />
            </Stack>
          )}
        </Grid>
        {validateTxtMin1 && (
          <Grid item xs={12}>
            <Typography
              variant="caption"
              sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
            >
              {validateTxtMin1}
            </Typography>
          </Grid>
        )}
      </Grid>
      {bio?.is_edu_achievement === true && (
        <EducationAchievement
          isEmptyAch={isEmptyAch}
          setIsEmptyAch={setIsEmptyAch}
        />
      )}

      {/* -------------------- INFORMAL EDUCATION ------------------- */}
      <Grid container sx={{ mt: "4rem" }}>
        <Grid item md={8} sm={12} sx={{ m: "auto 0" }}>
          <Typography>
            Apakah Anda memiliki keahlian teknis yang relevan dengan posisi ini?
          </Typography>
        </Grid>
        <Grid item md={2} sm={12}>
          {loadingCheck && <CircularProgress size={24} />}
          {!loadingCheck && (
            <Stack direction={"row"} spacing={2}>
              <FormControlLabel
                label="Tidak"
                error={validate && bio.is_edu_non === null}
                control={
                  <Checkbox
                    checked={bio?.is_edu_non === false}
                    onClick={(e) => handleUpdateBiodata("is_edu_non", false)}
                  />
                }
              />
              <FormControlLabel
                label="Pernah"
                error={validate && form.is_edu_non === null}
                control={
                  <Checkbox
                    checked={bio?.is_edu_non}
                    onClick={(e) => handleUpdateBiodata("is_edu_non", true)}
                  />
                }
              />
            </Stack>
          )}
        </Grid>
        {validateTxtMin2 && (
          <Grid item xs={12}>
            <Typography
              variant="caption"
              sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
            >
              {validateTxtMin2}
            </Typography>
          </Grid>
        )}
      </Grid>
      {bio?.is_edu_non === true && (
        <EducationInformal
          isEmptyNon={isEmptyNon}
          setIsEmptyNon={setIsEmptyNon}
        />
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: "1.5rem" }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val - 1] ? 1 : 0,
          }}
          onClick={(e) => handleChange(e, val - 1)}
          disabled={!validates[val - 1]}
          startIcon={<KeyboardArrowLeft />}
        >
          Data Keluarga
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val + 1] ? 1 : 0,
          }}
          onClick={(e) => {
            const conf =
              validateTxtMin ||
              validateTxtMin1 ||
              validateTxtMin2 ||
              (bio?.is_edu_achievement === true && isEmptyAch) ||
              (bio?.is_edu_non === true && isEmptyNon);
            if (conf) return;
            setValidateTxtMin(false);
            handleChange(e, val + 1);
          }}
          disabled={!validates[val + 1]}
          endIcon={<KeyboardArrowRight />}
        >
          Pengalaman Organisasi
        </Button>
      </Stack>
    </Container>
  );
};

export default Education;
