import { useEffect, forwardRef, useState } from "react";
import { useRoutes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import DateAdapter from "@mui/lab/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import MuiAlert from "@mui/material/Alert";
import GlobalStyles from "src/components/GlobalStyles";
import { Snackbar, CircularProgress, useTheme } from "@mui/material";
import getDesignTokens from "src/theme";
import routes from "src/routes";
import { connect } from "react-redux";
import Login from "src/components/Login";
import {
  setAuthenticated,
  setDeauthenticated,
  setOpenLogin,
  getInfoInstitution,
  setSnackbarClose,
  setLibPages,
  setMiddlewareApps,
} from "src/redux/actions";
import iconDef from "src/assets/img/favicon.ico";
import localforage from "localforage";
import { Helmet } from "react-helmet";
import { makeStyles } from "@mui/styles";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import MiddlewareApps from "./components/MiddlewareApps";
import "src/functions/i18n";
// import { pageview } from "src/analytics";

const useStyles = makeStyles(() => ({
  root: {
    fontSize: "20px !important",
  },
}));

const Alert = forwardRef(function Alert(props, ref) {
  const classes = useStyles();
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      icon={
        props.severity === "success" ? (
          <CheckCircleOutline className={classes.root} />
        ) : (
          <ErrorOutline className={classes.root} />
        )
      }
      {...props}
    />
  );
});

const App = ({
  openLogin,
  snackbar,
  isAuthenticated,
  isPreview,
  themePreview,
  mdlApps,
}) => {
  const theme = useTheme();
  const routing = useRoutes(routes);
  const [loading, setLoading] = useState(true);
  const [loadingInst, setLoadingInst] = useState(true);
  const [instName, setInstName] = useState("");
  const [instIcon, setInstIcon] = useState("");
  const [themeApp, setThemeApp] = useState({});

  useEffect(() => {
    fetchInfoInstitution();
  }, []);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = `${instIcon}`;
    localStorage.setItem("redirection", false);
    const checkToken = async () => {
      const resToken = await localforage.getItem("token");
      if (resToken) {
        await setAuthenticated(resToken);
      } else {
        await setDeauthenticated();
      }
      setLoading(false);
    };
    checkToken();
  }, [isAuthenticated]);

  // ini buat google ads
  useEffect(() => {
    const domain = window.location.hostname;

    // Tentukan kode iklan berdasarkan domain
    let googleAdsId = "";
    if (domain === "pesantrenkoding.com") {
      googleAdsId = "AW-16539641178";
      // Buat script untuk Google Ads
      const script1 = document.createElement("script");
      script1.src = `https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`;
      script1.async = true;

      const script2 = document.createElement("script");
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAdsId}');
      `;

      document.head.appendChild(script1);
      document.head.appendChild(script2);
    }
  }, []);

  // TODO: ini buat google analytics
  // useEffect(() => {
  //   const handlePageView = (location) => {
  //     pageview(location.pathname + location.search);
  //   };

  //   handlePageView(window.location);
  //   const unlisten = window.history.listen(handlePageView);

  //   return () => {
  //     unlisten();
  //   };
  // }, []);

  const fetchInfoInstitution = async () => {
    await getInfoInstitution();
    await setLibPages();
    const resBio = await localforage.getItem("bio");
    const resTheme = await localforage.getItem("theme");
    setInstName(resBio?.inst_name);
    setThemeApp(resTheme);
    setInstIcon(resBio.logo ? resBio.logo : iconDef);
    setLoadingInst(false);
  };

  // const colorTheme = createTheme(
  //   getDesignTokens(
  //     themePreview.colorPrimary
  //       ? themePreview.colorPrimary
  //       : getAppSetting.colorPrimary,
  //     themePreview.colorSecondary
  //       ? themePreview.colorSecondary
  //       : getAppSetting.colorSecondary,
  //     getAppSetting.websiteFor,
  //     isPreview
  //   )
  // );

  const colorTheme = createTheme(
    getDesignTokens(
      themePreview?.colorPrimary || themeApp?.colorPrimary,
      themePreview?.colorSecondary || themeApp?.colorSecondary,
      themePreview?.font || themeApp?.font,
      isPreview
    )
  );

  if (loadingInst)
    return (
      <div style={{ height: "100vh", display: "flex" }}>
        <CircularProgress
          sx={{
            color: `${theme.palette.primary.main}`,
            m: "auto",
          }}
        />
      </div>
    );

  return (
    <ThemeProvider theme={colorTheme}>
      <Helmet>
        <title>{instName}</title>
      </Helmet>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <GlobalStyles />

        {loading ? (
          <div style={{ width: "100%", height: "100%", display: "flex" }}>
            <CircularProgress
              sx={{
                color: `${theme.palette.primary.main}`,
                m: "auto",
                display: "flex",
              }}
            />
          </div>
        ) : (
          routing
        )}
        <Login open={openLogin} onClose={() => setOpenLogin(false)} />
        <MiddlewareApps
          open={mdlApps?.open || false}
          onClose={() =>
            setMiddlewareApps(false, [], { email: "", password: "" }, null)
          }
          mdlApps={mdlApps}
        />
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbarClose()}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Alert severity={snackbar.type}>{snackbar.msg}</Alert>
        </Snackbar>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  openLogin: state.auth.openLogin,
  snackbar: state.app.snackbar,
  isPreview: state.preview.isPreview,
  isAuthenticated: state.auth.isAuthenticated,
  themePreview: state.preview.themePreview,
  mdlApps: state.auth.mdlApps,
});

export default connect(mapStateToProps)(App);
