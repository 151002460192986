import React from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  styled,
  CircularProgress,
  DialogActions,
  Grid,
  Paper,
} from "@mui/material";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
    // maxWidth: "350px",
  },
}));

const ModalNeop = ({ open, onClose, candidateNeop, loadingModal }) => {
  useTheme();

  const handleStart = (auth) => {
    const newWindow = window.open(
      `${window.location.origin}/entrance/pages/login?email=${auth.user_id}&password=${auth.password}&ecommerceUrl=${window.location.hostname}`,
      "_self",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  if (loadingModal) return <CircularProgress />;

  return (
    <CustomDialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        <b>NEOP</b>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6">{candidateNeop[0]?.publish_g_name}</Typography>
        {candidateNeop.map((cn) => (
          <Paper sx={{ p: "0.5rem 1rem" }}>
            <Grid container>
              <Grid item xs={10.5} sx={{ m: "auto" }}>
                <Typography>{cn.publish_name}</Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Button
                  variant="contained"
                  onClick={() => handleStart(cn.auth)}
                >
                  Mulai
                </Button>
              </Grid>
            </Grid>
          </Paper>
        ))}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Tutup</Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default ModalNeop;
