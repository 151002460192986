import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Typography, Button, CircularProgress, Alert } from "@mui/material";
import { getCandidateNeop, myJobs, setSnackbarOpen } from "src/redux/actions";
import localforage from "localforage";
import { domain } from "src/components/Layout/dummy";
import ModalSelectDateMCU from "../Modal/ModalSelectDateMCU";
import ModalSignContractRequired from "../Modal/ModalSignContractRequired";
import TableMaterial from "./TableMaterial";
import ModalUploadTreatment from "../Modal/ModalUploadTreatment";
import ModalUploadReimburse from "../Modal/ModalUploadReimburse";
import ModalNeop from "../Modal/ModalNeop";
import ModalConfirmTest from "../Modal/ModalConfirmTest";

const MyCareer = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);
  const [openModal, setOpenModal] = useState("");
  const [dataDetail, setDataDetail] = useState({});
  const [openReimburse, setOpenReimburse] = useState(false);
  const [detailReimburse, setDetailReimburse] = useState({});
  const [candidateNeop, setCandidateNeop] = useState([]);
  const [font, setFont] = useState("");

  useEffect(() => {
    fetchData();
  }, [openReimburse]);

  const fetchData = async () => {
    setLoading(true);
    const resTheme = await localforage.getItem("theme");
    if (resTheme.websiteFor === "recruitment") {
      const resJobs = await myJobs({ domain });
      setData(resJobs.data);
    }
    setFont(resTheme.font);
    setLoading(false);
  };

  const handleStart = (d) => {
    const psikotestStartAt = new Date(d?.psikotest?.started_at_ftd);
    const psikotestFinishAt = new Date(d?.psikotest?.finished_at_ftd);
    const dateNow = new Date();
    if (d?.candidate_logs[0]?.ref_pipeline?.pipeline_type === "call") {
      const cnLastLogs = d?.candidate_logs[0];
      if (cnLastLogs?.meeting_eligible) {
        window.open(cnLastLogs?.meeting_room?.meeting_url);
      } else {
        setSnackbarOpen(
          "error",
          "Anda sedang tidak berada pada waktu Interview"
        );
      }
      return;
    }

    if (dateNow.getTime() >= psikotestStartAt.getTime()) {
      if (dateNow.getTime() <= psikotestFinishAt.getTime()) {
        const participantId = d.psikotest?.user_data?.user_id;
        const pass = d.psikotest?.user_data?.password;
        const newWindow = window.open(
          // `https://entrance.shratech.com/entrance/pages/login?email=${participantId}&password=${pass}&ecommerceUrl=${window.location.hostname}`,
          `${window.location.origin}/entrance/pages/login?email=${participantId}&password=${pass}&ecommerceUrl=${window.location.hostname}`,
          "_self",
          "noopener,noreferrer"
        );
        if (newWindow) newWindow.opener = null;
      } else {
        setSnackbarOpen("error", "Waktu psikotest sudah selesai");
      }
    } else {
      setSnackbarOpen("error", "Belum masuk waktu pengerjaan");
    }
  };

  const handleOpenModal = (d, type) => {
    setDataDetail(d);
    setOpenModal(type);
  };

  const handleOpenReimburse = (d) => {
    setDetailReimburse(d);
    setOpenReimburse(true);
  };

  const handleOpenNeop = async (id) => {
    setOpenModal("neop");
    setLoadingModal(true);
    const res = await getCandidateNeop({ candidate_neop_id: id });
    setCandidateNeop(res.data);
    console.log(res.data);
    setLoadingModal(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Alert severity="info" sx={{ mb: "1rem" }}>
        Mohon untuk mengecek email secara berkala dan dashboard progress seleksi
        secara berkala.
      </Alert>
      <TableMaterial
        title=""
        selection={false}
        columns={[
          { title: "Job Title", field: "position_group.position_group_name" },
          { title: "Location", field: "job_location_name" },
          {
            title: "Status",
            field: "candidate_logs[0].log_type",
            render: (d) => {
              if (d.extension && d.extension?.length) {
                const lastHistory =
                  d.extension[0]?.history[d.extension[0].history?.length - 1];
                return (
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {lastHistory?.candidate}
                  </Typography>
                );
              } else {
                const progress =
                  d?.candidate_logs &&
                  d?.candidate_logs[0]?.ref_pipeline?.pipeline_name;
                const strStatus =
                  d?.candidate_logs && d?.candidate_logs[0]?.log_type === "0"
                    ? "Gagal"
                    : d?.candidate_logs &&
                      d?.candidate_logs[0]?.log_type === "1"
                    ? "Menunggu"
                    : d?.candidate_logs &&
                      d?.candidate_logs[0]?.log_type === "2"
                    ? "Terjadwal"
                    : d?.candidate_logs &&
                      d?.candidate_logs[0]?.log_type === "9"
                    ? "Lulus"
                    : d?.candidate_logs &&
                      d?.candidate_logs[0]?.log_type === "3"
                    ? "Selesai"
                    : d?.candidate_logs[0]?.log_type === "4"
                    ? "Hadir"
                    : d?.candidate_logs[0]?.log_type === "5"
                    ? "Tidak Hadir"
                    : d?.candidate_logs[0]?.log_type === "1.5"
                    ? "Reschedule, Menunggu"
                    : d?.candidate_logs[0]?.log_type === "2.5"
                    ? "Reschedule, Terjadwal"
                    : "";
                return (
                  <>
                    {d?.candidate_logs &&
                    d?.candidate_logs[0]?.log_type === "2" ? (
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {progress} {strStatus} <br />
                        {d?.candidate_logs[0]?.ref_pipeline?.pipeline_type ===
                        "call" ? (
                          <span
                            style={{ fontSize: "12px", fontWeight: "normal" }}
                          >
                            {d?.candidate_logs[0]?.started_at_ftd}
                            {/* -{" "}{d?.candidate_logs[0]?.finished_at_ftd} */}
                          </span>
                        ) : (
                          <span
                            style={{ fontSize: "12px", fontWeight: "normal" }}
                          >
                            {d?.psikotest?.started_at_ftd} -{" "}
                            {d?.psikotest?.finished_at_ftd}
                          </span>
                        )}
                      </Typography>
                    ) : (
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        {progress === "Validasi RAS"
                          ? strStatus === "Menunggu"
                            ? "Menunggu Hasil Interview Psikolog"
                            : strStatus + " Interview Psikolog"
                          : strStatus + " " + progress}
                        {d?.candidate_logs[0]?.ref_pipeline?.pipeline_type ===
                        "call" ? (
                          <>
                            <br />
                            <span
                              style={{ fontSize: "12px", fontWeight: "normal" }}
                            >
                              {d?.candidate_logs[0]?.started_at_ftd}
                            </span>
                          </>
                        ) : (
                          <span
                            style={{ fontSize: "12px", fontWeight: "normal" }}
                          >
                            {d?.psikotest?.started_at_ftd} -{" "}
                            {d?.psikotest?.finished_at_ftd}
                          </span>
                        )}
                      </Typography>
                    )}
                  </>
                );
              }
            },
          },
          {
            title: "Action",
            render: (d) => {
              if (d.neop?.candidate_neop_id) {
                return (
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "0.75rem" }}
                    onClick={() => handleOpenNeop(d.neop?.candidate_neop_id)}
                  >
                    NEOP
                  </Button>
                );
              }
              if (d.extension && d.extension?.length) {
                const lastHistory =
                  d.extension[0]?.history[d.extension[0].history?.length - 1];
                let actionMsg = null;
                if (lastHistory?.actions?.is_action) {
                  if (lastHistory?.actions?.action_type === "mcu-select-date") {
                    actionMsg = "Pilih Tanggal";
                  } else if (
                    lastHistory?.actions?.action_type ===
                    "request-to-complete-data"
                  ) {
                    actionMsg = "Lengkapi Data";
                  }

                  if (
                    lastHistory?.actions?.action_type === "upload-treatment"
                  ) {
                    actionMsg = "Upload Dokumen";
                  }
                }
                return (
                  <Button
                    variant="contained"
                    sx={{ borderRadius: "0.75rem" }}
                    disabled={!actionMsg}
                    onClick={() =>
                      handleOpenModal(d, lastHistory?.actions?.action_type)
                    }
                  >
                    {actionMsg || "Mulai"}
                  </Button>
                );
              } else {
                let isDisableButton =
                  d?.candidate_logs[0]?.ref_pipeline?.pipeline_type === "call"
                    ? !d?.candidate_logs[0]?.meeting_eligible
                    : d?.candidate_logs[0]?.log_type !== "2";
                return (
                  <>
                    <Button
                      variant="contained"
                      sx={{ borderRadius: "0.75rem" }}
                      disabled={isDisableButton}
                      onClick={() => handleStart(d)} //handleStart(d)
                      // onClick={() => handleOpenModal(d, "confirm-test")} //handleStart(d)
                    >
                      Mulai
                    </Button>
                  </>
                );
              }
            },
          },
          // TODO: disable reimburse dulu
          {
            title: "Reimburse",
            hidden: !data[0]?.reimburse[0]?.candidate_reimburse_id,
            render: (d) => {
              if (d.reimburse) {
                return (
                  <>
                    {d.reimburse?.map((r) => (
                      <Button
                        disabled={!r.actions?.is_action}
                        variant="contained"
                        onClick={() => handleOpenReimburse(r)}
                      >
                        {r.actions?.action_type === "upload-file-reimburse" &&
                          "Upload File Reimburse"}
                      </Button>
                    ))}
                  </>
                );
              }
            },
          },
        ]}
        data={data}
        pageSize={10}
        isLoading={loading}
        font={font}
      />
      <ModalSelectDateMCU
        open={openModal === "mcu-select-date"}
        onClose={() => {
          fetchData();
          setOpenModal("");
        }}
        dataDetail={dataDetail}
      />

      <ModalSignContractRequired
        open={openModal === "request-to-complete-data"}
        onClose={() => {
          fetchData();
          setOpenModal("");
        }}
        dataDetail={dataDetail}
      />

      {openModal === "upload-treatment" && (
        <ModalUploadTreatment
          open={openModal === "upload-treatment"}
          onClose={() => {
            fetchData();
            setOpenModal("");
          }}
          dataDetail={dataDetail}
        />
      )}

      <ModalUploadReimburse
        open={openReimburse}
        onClose={() => setOpenReimburse(false)}
        dataDetail={detailReimburse}
      />

      <ModalNeop
        open={openModal === "neop"}
        onClose={() => setOpenModal("")}
        loadingModal={loadingModal}
        candidateNeop={candidateNeop}
      />

      <ModalConfirmTest
        open={openModal === "confirm-test"}
        onClose={() => setOpenModal("")}
        loadingModal={loadingModal}
        dataDetail={dataDetail}
        handleStart={handleStart}
      />
    </>
  );
};

export default MyCareer;
