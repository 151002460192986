import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  lighten,
  darken,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {
  refWorkerStatus,
  createExperience,
  getExperience,
  updateExperience,
  deleteExperience,
  setSnackbarOpen,
  getRefFieldWork,
  getRef,
  getBiodata,
  updateBiodata,
} from "src/redux/actions";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import CustomDatePicker from "src/components/CustomDatePicker";
import { parsedDate } from "src/functions/parsedDate";
import { formatRupiah } from "src/functions/utils";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const Experience = ({ handleChange }) => {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState("");
  const [workerStatus, setWorkerStatus] = useState([]);
  const [form, setForm] = useState({});
  const [experiences, setExperiences] = useState([]);
  const [refIndustry, setRefIndustry] = useState([]);
  const [refFieldWork, setRefFieldWork] = useState([]);
  const [refPositionLevel, setRefPositionLevel] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [validate, setValidate] = useState(false);
  const [loadingCheck, setLoadingCheck] = useState(false);
  const [bio, setBio] = useState({});
  const [validateTxtMin, setValidateTxtMin] = useState("");
  const [isEmptyExp, setIsEmptyExp] = useState(false);
  const validates = JSON.parse(localStorage.getItem("validates"));
  const val = parseInt(localStorage.getItem("value"));

  useEffect(() => {
    validates[val + 1] = true;
    localStorage.setItem("validates", JSON.stringify(validates));
    fetchRef();
    fetchExp();
  }, []);

  useEffect(() => {
    if (bio?.is_experiences === null) {
      setValidateTxtMin("* required");
    } else {
      setValidateTxtMin("");
    }
  }, [experiences, bio]);

  const fetchRef = async () => {
    const resRef = await Promise.all([
      refWorkerStatus(),
      getRef({ type: "refFieldIndustry" }),
      getRef({ type: "refPositionLevel" }),
      getRefFieldWork(),
    ]);
    setWorkerStatus(resRef[0].data);
    setRefIndustry(resRef[1].data);
    setRefPositionLevel(resRef[2].data);
    setRefFieldWork(resRef[3].data);
  };

  const fetchExp = async () => {
    setLoading(true);
    const resExp = await Promise.all([getExperience(), getBiodata()]);
    setExperiences(resExp[0].data);
    setIsEmptyExp(resExp[0].data?.length > 0 ? false : true);
    resExp[1].data[0].city = resExp[1].data[0].city?.city;
    resExp[1].data[0].kecamatan = resExp[1].data[0].kecamatan?.kecamatan;
    resExp[1].data[0].kelurahan = resExp[1].data[0].kelurahan?.kelurahan;
    resExp[1].data[0].province = resExp[1].data[0].province?.province;
    setBio(resExp[1].data[0] || {});
    setLoading(false);
  };

  const handleSelectAuto = async (val, key) => {
    if (key === "status") {
      setForm({ ...form, worker_status: val, status: val.worker_status_id });
      return;
    }
    setForm({ ...form, [key]: val[key] });
  };

  const handleDateChange = (val, name) => {
    setForm({ ...form, [name]: val });
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteExperience(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    fetchExp();
  };

  function tambahkanSatuHari(tanggal) {
    let tanggalBaru = new Date(tanggal);
    tanggalBaru.setHours(tanggalBaru.getHours() + 12);
    return tanggalBaru;
  }

  const handleSave = async () => {
    form.started_at = tambahkanSatuHari(form?.started_at);
    form.finished_at = tambahkanSatuHari(form?.finished_at);
    if (openForm === "new") {
      await createExperience(form);
    } else {
      await updateExperience(form);
    }
    setSnackbarOpen("success", "Data disimpan");
    setOpenForm("");
    setForm({});
    fetchExp();
  };

  const handleUpdateBiodata = async (key, val) => {
    setLoadingCheck(true);
    const newBio = { ...bio, [key]: val };
    await updateBiodata(newBio);
    setBio(newBio);
    setLoadingCheck(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Pengalaman Kerja</b>{" "}
        <span
          style={{ fontSize: "16px" }}
        >{`(Tulis maksimal 3 yang relate dengan posisi yang dilamar)`}</span>
      </Typography>
      <Grid container sx={{ mt: "4rem" }}>
        <Grid item md={8} sm={12} sx={{ m: "auto 0" }}>
          <Typography>Apakah Anda memiliki pengalaman kerja?</Typography>
        </Grid>
        <Grid item md={2} sm={12}>
          {loadingCheck && <CircularProgress size={24} />}
          {!loadingCheck && (
            <Stack direction={"row"} spacing={2}>
              <FormControlLabel
                label="Tidak"
                error={validate && bio.is_experiences === null}
                control={
                  <Checkbox
                    checked={bio?.is_experiences === false}
                    onClick={(e) =>
                      handleUpdateBiodata("is_experiences", false)
                    }
                  />
                }
              />
              <FormControlLabel
                label="Ya"
                error={validate && form.is_experiences === null}
                control={
                  <Checkbox
                    checked={bio?.is_experiences}
                    onClick={(e) => handleUpdateBiodata("is_experiences", true)}
                  />
                }
              />
            </Stack>
          )}
        </Grid>
        {validateTxtMin && (
          <Grid item xs={12}>
            <Typography
              variant="caption"
              sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
            >
              {validateTxtMin}
            </Typography>
          </Grid>
        )}
      </Grid>
      {isEmptyExp && bio?.is_experiences === true && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
        >
          * required write down at least 1 experience
        </Typography>
      )}

      {bio?.is_experiences === true && !openForm && (
        <Button
          variant="contained"
          sx={{ display: "flex", m: "auto 0 1rem auto" }}
          onClick={() => setOpenForm("new")}
        >
          Tambah
        </Button>
      )}
      {openForm && (
        <>
          <CustomInput
            label="Nama Perusahaan"
            name="institute_name"
            onChange={handleChangeInput}
            value={form.institute_name}
            error={validate && !form.institute_name}
          />
          {/* <CustomInput
            label="Bidang Industri"
            name="field"
            onChange={handleChangeInput}
            value={form.field}
          /> */}
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Bidang Industri
          </Typography>
          <CustomAutoComplete
            name="field_industry_id"
            optionsArr={refIndustry}
            keyLabel={"field_industry_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={
              refIndustry.filter(
                (r) => r.field_industry_id === form.field_industry_id
              )[0]
            }
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Bidang Pekerjaan
          </Typography>
          <CustomAutoComplete
            name="field_work_id"
            optionsArr={refFieldWork}
            keyLabel={"field_work_name"}
            groupBy={(option) => option["field_work_group_name"]}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={
              refFieldWork.filter(
                (r) => r.field_work_id === form.field_work_id
              )[0]
            }
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
          />
          <CustomInput
            label="Nama Posisi"
            name="position"
            onChange={handleChangeInput}
            value={form.position}
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Level Posisi
          </Typography>
          <CustomAutoComplete
            name="position_level_id"
            optionsArr={refPositionLevel}
            keyLabel={"position_level_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={
              refPositionLevel.filter(
                (r) => r.position_level_id === form.position_level_id
              )[0]
            }
          />
          <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
            Status Pekerja
          </Typography>
          <CustomAutoComplete
            name="status"
            optionsArr={workerStatus}
            keyLabel={"worker_status_name"}
            handleSelect={handleSelectAuto}
            register="true"
            style={{ marginBottom: "1rem" }}
            value={form.worker_status}
          />
          <CustomDatePicker
            label="Tanggal Mulai"
            onChange={handleDateChange}
            name="started_at"
            value={form.started_at ? form.started_at : null}
          />
          <CustomDatePicker
            label="Tanggal Selesai"
            onChange={handleDateChange}
            name="finished_at"
            value={form.finished_at ? form.finished_at : null}
          />
          <CustomInput
            label="Alasan Berhenti"
            multiline
            rows={4}
            name="reason_to_stop"
            onChange={handleChangeInput}
            value={form.reason_to_stop}
          />
          <CustomInput
            label="Uraian Pekerjaan"
            multiline
            rows={4}
            name="descriptions"
            onChange={handleChangeInput}
            value={form.descriptions}
          />
          <CustomInput
            label="Pendapatan Bersih"
            name="net_income"
            onChange={handleChangeInput}
            value={form.net_income}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent={"end"}
            sx={{ my: "1rem" }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Simpan
            </Button>
          </Stack>
          <Divider sx={{ mt: 2, mb: 2 }} />
        </>
      )}
      {bio?.is_experiences === true &&
        experiences?.map((exp) => (
          <Card
            id={exp.bio_experience_id}
            sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "16px" }}
          >
            <Grid container>
              <Grid item sm={10}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "bold", mb: "1rem" }}
                >
                  {exp.institute_name}{" "}
                  <span style={{ fontWeight: "normal" }}>{` (${parsedDate(
                    exp.started_at
                  )} s.d. ${parsedDate(exp.finished_at)})`}</span>
                </Typography>
                <Grid container>
                  <Grid item sm={3}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Bidang Industri
                    </Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      : {exp.field_industry_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={3}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Bidang Pekerjaan
                    </Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      : {exp.field_work_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={3}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Nama Posisi
                    </Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      : {exp.position}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={3}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Level Posisi
                    </Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      : {exp.position_level_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={3}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Status Pekerja
                    </Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      : {exp.worker_status?.worker_status_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={3}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Alasan Berhenti
                    </Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      : {exp.reason_to_stop}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={3}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Uraian Pekerjaan
                    </Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      : {exp.descriptions}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item sm={3}>
                    <Typography sx={{ fontSize: "14px" }}>
                      Pendapatan Bersih
                    </Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      : {formatRupiah(exp.net_income || "0")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={2} sx={{ m: "auto" }}>
                <Stack direction="row" justifyContent={"end"} spacing={1}>
                  <IconButton onClick={() => handleEdit(exp)}>
                    <EditRoundedIcon />{" "}
                  </IconButton>
                  <IconButton onClick={() => handleDelete(exp)}>
                    <DeleteRoundedIcon />{" "}
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        ))}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: "1.5rem" }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val - 1] ? 1 : 0,
          }}
          onClick={(e) => handleChange(e, val - 1)}
          disabled={!validates[val - 1]}
          startIcon={<KeyboardArrowLeft />}
        >
          Pengalaman Organisasi
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val + 1] ? 1 : 0,
          }}
          onClick={(e) => {
            const conf =
              validateTxtMin || (bio?.is_experiences === true && isEmptyExp);
            if (conf) return;
            handleChange(e, val + 1);
          }}
          disabled={!validates[val + 1]}
          endIcon={<KeyboardArrowRight />}
        >
          Pernyataan Pribadi
        </Button>
      </Stack>

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          Data{" "}
          <b>
            {form.institute_name} - {form.position}
          </b>{" "}
          akan dihapus
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>
    </Container>
  );
};

export default Experience;
