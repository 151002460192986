import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Divider,
  Card,
  Grid,
  Stack,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  styled,
  CircularProgress,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import {
  getAchievementEducation,
  createAchievementEducation,
  updateAchievementEducation,
  deleteAchievementEducation,
  setSnackbarOpen,
} from "src/redux/actions";
import CustomDatePicker from "src/components/CustomDatePicker";

const CustomDialog = styled(Dialog)(() => ({
  "& .MuiDialog-paper": {
    backgroundColor: "rgb(255,255,255)",
    borderRadius: "1rem",
    border: "1px solid lightgrey",
  },
}));

const EducationAchievement = ({ isEmptyAch, setIsEmptyAch }) => {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState("");
  const [form, setForm] = useState({
    started_at: new Date(),
    finished_at: new Date(),
  });
  const [educations, setEducations] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    fetchEdu();
  }, []);

  const fetchEdu = async () => {
    setLoading(true);
    const resEdu = await getAchievementEducation();
    setEducations(resEdu.data);
    setIsEmptyAch(resEdu.data?.length > 0 ? false : true);
    setLoading(false);
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleEdit = (d) => {
    setForm(d);
    setOpenForm("edit");
  };

  const handleDelete = (d) => {
    setForm(d);
    setAlertOpen(true);
  };

  const handleSubmitDelete = async () => {
    await deleteAchievementEducation(form);
    setForm({});
    setSnackbarOpen("success", "Data berhasil dihapus");
    setAlertOpen(false);
    fetchEdu();
  };

  const handleSave = async () => {
    const copyForm = { ...form };
    if (openForm === "new") {
      await createAchievementEducation(copyForm);
    } else {
      await updateAchievementEducation(copyForm);
    }
    setSnackbarOpen("success", "Data disimpan");
    setOpenForm("");
    setForm({});
    fetchEdu();
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Stack
        direction="row"
        justifyContent={"space-between"}
        sx={{ m: "0 0 1rem 0" }}
      >
        <Typography sx={{ fontSize: "20px", m: "auto 0" }}>
          Prestasi Semasa Studi
        </Typography>
        {!openForm && (
          <Button variant="contained" onClick={() => setOpenForm("new")}>
            Tambah
          </Button>
        )}
      </Stack>
      <Grid container>
        <Grid item sm={3} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Nama Prestasi</Typography>
        </Grid>
        <Grid item sm={8} sx={{ m: "auto" }}>
          <Typography sx={{ fontSize: "14px" }}>Deskripsi</Typography>
        </Grid>
        <Grid item sm={1} sx={{ m: "auto" }}></Grid>
      </Grid>
      {isEmptyAch && (
        <Typography
          variant="caption"
          sx={{ color: "#ff0000", mb: 4, mt: 4, textAlign: "center" }}
        >
          * required write down at least 1 achievement
        </Typography>
      )}
      {educations.map((edu) => (
        <Card
          id={edu.bio_edu_achievement_id}
          sx={{ backgroundColor: "#FFF", p: 2, borderRadius: "16px" }}
        >
          <Grid container>
            <Grid item sm={3} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                {edu.name}
              </Typography>
            </Grid>
            <Grid item sm={8} sx={{ m: "auto" }}>
              <Typography sx={{ fontSize: "14px" }}>
                {edu.descriptions}
              </Typography>
            </Grid>
            <Grid item sm={1} sx={{ m: "auto" }}>
              <Stack direction="row" justifyContent={"end"} spacing={1}>
                <IconButton onClick={() => handleEdit(edu)}>
                  <EditRoundedIcon />{" "}
                </IconButton>
                <IconButton onClick={() => handleDelete(edu)}>
                  <DeleteRoundedIcon />{" "}
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      ))}
      <Divider sx={{ mt: 2, mb: 2 }} />
      {openForm && (
        <Stack
          sx={{
            border: "1px solid",
            p: "1rem",
            borderRadius: "6px",
            borderColor: "divider",
          }}
        >
          <CustomInput
            label="Nama Prestasi"
            name="name"
            onChange={handleChangeInput}
            value={form.name}
          />
          <CustomInput
            label="Deskripsi"
            onChange={handleChangeInput}
            name="descriptions"
            value={form.descriptions}
            multiline
            rows={4}
          />
          <Stack direction="row" spacing={2} justifyContent={"end"}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpenForm("");
                setForm({});
              }}
            >
              Batal
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Simpan
            </Button>
          </Stack>
        </Stack>
      )}

      <CustomDialog open={alertOpen} fullWidth maxWidth="xs">
        <DialogTitle>Konfirmasi</DialogTitle>
        <DialogContent>
          Data <b>{form.name}</b> akan dihapus
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setAlertOpen(false);
              setForm({});
            }}
          >
            Batal
          </Button>
          <Button variant="contained" onClick={handleSubmitDelete}>
            Ya, Hapus
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default EducationAchievement;
